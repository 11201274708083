const Color = {
  /** black: rgb(55, 53, 47) */
  black: '#37352F',

  /** blackBright1: rgb(51, 51, 51) */
  blackBright1: '#333333',

  /** blackBright2: rgb(64, 64, 64) */
  blackBright2: '#404040',

  /** blackGlossy: rgb(15, 15, 15) */
  blackGlossy: '#0F0F0F',

  /** blackSidebar: rgb(25, 23, 17) */
  blackSidebar: '#191711',

  /** black9: rgb(238, 238, 237) */
  black9: '#EEEEED',

  /** grey1: rgb(242, 241, 238) */
  grey1: '#F2F1EE',

  /** grey2: rgb(135, 131, 120) */
  grey2: '#878378',

  /** grey3: rgb(206, 205, 202) */
  grey3: '#CECDCA',

  /** grey4: rgb(196, 196, 196) */
  grey4: '#C4C4C4',

  /** : grey5 rgb(241, 241, 239) */
  grey5: '#F1F1EF',

  /** greySolid: rgba(120, 119, 116) */
  greySolid: '#787774',

  /** grey9: rgb(238, 238, 238) */
  grey9: '#FBFBFA',

  /** grey10: rgba(241, 241, 239, 1) */
  grey10: '#F1F1EF',

  /** grey11: rgba(233, 233, 231, 1) */
  grey11: '#E9E9E7',

  /** grey12: rgba(227, 226, 224, 1) */
  grey12: '#E3E2E0',

  /** grey13: rgba(172, 171, 169) */
  grey13: '#ACABA9',

  /** white: rgb(255, 255, 255) */
  white: '#FFFFFF',

  /** blue: rgb(17, 100, 163) */
  blue: '#1164A3',

  /** blue2: rgb(231, 243, 248)*/
  blue2: '#E7F3F8',

  /** blueSolid: rgb(51, 126, 169)*/
  blueSolid: '#337EA9',

  /** blueSelection: rgb(0, 113, 187, 0.2)*/
  blueSelection: '#0071BB',

  /** blueBright1: rgb(35, 131, 226) */
  blueBright1: '#2383E2',

  /** blueDim1: rgb(0, 102, 168) */
  blueDim1: '#0066A8',

  /** blueDim2: rgb(0, 90, 150) */
  blueDim2: '#005A96',

  /** red: rgb(235, 87, 87) */
  red: '#EB5757',

  /** red2: rgb(244, 240, 247, 0.8) */
  red2: '#F4F0F7',

  /** redSolid: rgb(212, 76, 71) */
  redSolid: '#D44C47',

  /** redDim2 rgb(253, 235, 236)  */
  redDim2: '#FDEBEC',

  /** orange: rgb(246, 192, 80) */
  orange: '#F6C050',

  /** orange2: rgb(251, 236, 221); */
  orange2: '#FBECDD',

  /** orangeSolid: rgb(217, 115, 13); */
  orangeSolid: '#D9730D',

  /** orangeDim1: rgb(207, 136, 7) */
  orangeDim1: '#CF8807',

  /** brown1: rgb(244, 238, 238) */
  brown1: '#F4EEEE',

  /** brownSolid: rgb(159, 107, 83) */
  brownSolid: '#9F6B53',

  /** yellow1: rgb(251, 243, 219) */
  yellow1: '#FBF3DB',

  /** yellowSolid: rgb(203, 145, 47) */
  yellowSolid: '#CB912F',

  /** green1: rgb(237, 243, 236)  */
  green1: '#EDF3EC',

  /** greenSolid: rgb(68, 131, 97)  */
  greenSolid: '#448361',

  /** pink1: rgba(249, 238, 243, 0.8 */
  pink1: '#F9EEF3',

  /** pinkSolid: rgba(193, 76, 138) */
  pinkSolid: '#C14C8A',

  /** purple1: rgba(244, 240, 247, 0.8); */
  purple1: '#F4F0F7',

  /** purpleSolid: rgb(144, 101, 176); */
  purpleSolid: '#9065B0',
} as const;

export { Color as ColorV2 };
